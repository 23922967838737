import React from 'react';
import Sdata from "./Sdata"
import Card from'./Card';
const Service=()=>{
    return(
        <>
    <div className="my-5">
        <h1 className="text-center">Our Service</h1>
        <div className="container-fluid mb-5">
    <div className="row">
        <div className="col-10  mx-auto">
      <div className="row gy-4">
        {
          Sdata.map((value,index)=>{
                return <Card  key={index}
                image={value.image} title={value.title}
                />
          })
        }
      </div>
        </div>
    </div>
      </div>
    </div>
    </>
    )
};
export default Service;