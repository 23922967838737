import React from 'react';
import img1 from '../src/img/abo.jpg'
import Common from './Common';
const About=()=>{
    return(
        <>
     <Common name='Welcome to about page' visit="/contact" image={img1} btnName="Contact Now"/>
    </>
    )
};
export default About;