import ser1 from '../src/img/ser1.png'
import ser2 from '../src/img/ser2.jpg'
import ser3 from '../src/img/ser3.jpeg'
import ser4 from '../src/img/ser4.jpg'
import ser5 from '../src/img/ser5.jpeg'
import ser6 from '../src/img/ser6.jpg'

const Sdata = [
    {
        image : ser1 ,
        title : "Web Development",
    },
    {
        image : ser2 ,
        title : "Web Design",
    },
    {
        image : ser3 ,
        title : "App Development",
    },
    {
        image : ser4 ,
        title : "Soft Development",
    },
    {
        image : ser5 ,
        title : "Digital Marketing",
    },
    {
        image : ser6 ,
        title : "Marketing",
    }

]
export default Sdata;