import React, { useState } from 'react';
const Contact=()=>{

    const [data,setData] = useState({
        fullname:'',
        phone:'',
        email:'',
        msg:'',
    })

     const InputEvent=(event)=>{
           const {name,value} = event.target ;
           setData((preVal)=>{
               return {
                ...preVal,
                [name]: value ,
               }
           })
           
     }
    const formSubmit=(e)=>{
         e.preventDefault();
         alert(`My name is ${data.fullname} , My Phone numer is ${data.phone} ,My Email id is ${data.email} and I wanna say ${data.msg} `)
    }
    return(
        <>
    <div className="my-5">
        <h1 className="text-center">Contact US</h1>
    </div>
    <div className="container">
        <div className="row">
            <div className="col-10 col-md-6 mx-auto">
           <form className='mt-2' onSubmit={formSubmit}>
           <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">Full Name</label>
  <input type="text" className="form-control" id="exampleFormControlInput1" name='fullname' value={data.fullname} onChange={InputEvent} required placeholder="Enter your Name"/>
</div>
           <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">Mobile Number</label>
  <input type="text" className="form-control" id="exampleFormControlInput1" name='phone' value={data.phone} onChange={InputEvent} required placeholder="Enter your Number"/>
</div>
           <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">Email address</label>
  <input type="email" className="form-control" id="exampleFormControlInput1" name='email' value={data.email} onChange={InputEvent} required placeholder="name@example.com"/>
</div>
<div className="mb-3">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">Message for Us</label>
  <textarea className="form-control" id="exampleFormControlTextarea1" name='msg' value={data.msg} onChange={InputEvent}  rows="3"></textarea>
</div>
<div className="col-12">
    <button className="btn btn-outline-primary" type="submit">Submit form</button>
  </div>
           </form>
            </div>
        </div>
    </div>
    </>
    )
};
export default Contact;