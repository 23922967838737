import React from "react";
const Footer=()=>{
    return(
        <>
        <footer className="container footer mt-5"> 
                <div className="icon container"><div className="icont"> 
                <div className="cont">Connect Us on: </div> 
                 <div className="iconi"><i className="fa-brands fa-instagram"></i> <i className="fa-brands fa-facebook"></i><i className="fa-brands fa-twitter"></i></div>
                </div> 
                </div> 
                <p className="footerp">&copy; Copy right Reserved {new Date().getFullYear()}. <strong>Satya-Devop</strong></p>
        </footer>
        </>
    )
}
export default Footer ;