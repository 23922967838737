import React from 'react';
import img1 from '../src/img/img1.jpeg'
import Common from './Common';
const Home=()=>{
    return(
        <>
     <Common name='Grow your business with ' visit="/service" image={img1} btnName="Get Started"/>
    </>
    )
};
export default Home;